const Server = {

    // This method returns the connection string to the API. 
    // For Dev/Test, return https://smartplates-api-test.azurewebsites.net
    // To prod, return https://smartplates-api.azurewebsites.net
    // For local, return https://localhost:5001
    // The Azure pipeline will substitute the hash const with the prod or dev one.
    getConnection() {
        const ApiUrl = 'https://api.smartplates.pt'

        if (ApiUrl.includes("#{")) {
            return "https://smartplates-api-test.azurewebsites.net"
        } else {
            return ApiUrl
        }
    },

    getBuildNumber() {
        const buildNumber = '20240304.1'
        if (buildNumber.includes("buildNumber")) {
            return "LOCAL";
        }
        return buildNumber;
    },

    getEnvironment() {
        const ApiUrl = 'PROD'

        if (ApiUrl === "PROD")
            return 'Production'

        if (ApiUrl === "TEST")
            return "Test"

        return "Local Development"
    }
};

export default Server;
