import React from 'react';
import { UserContext } from '../../contexts/userContext';
import Translate from "../../internationalisation/translations";

const iconStyle = {
  marginRight: '15px',
};

export default class ContactUsDetails extends React.Component {
  static contextType = UserContext;
  
  render() {
    const {languageCode} = this.context;
    
    return (
      <div>
        <h3>{Translate.getTranslation(languageCode, "TEXT_ContactDetails")}</h3>
        <p>
          <span className="fa fa-envelope" style={iconStyle} />
          <a href="mailto:info@smartplates.pt">info@smartplates.pt</a>
        </p>
        <p>
          <i className="fa fa-clock" style={iconStyle} />
          {Translate.getTranslation(languageCode, "TEXT_OpeningTimes")}
        </p>
        <p>
          <a
            href="https://www.facebook.com/smartplates.pt/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-facebook-square" style={iconStyle} />
          </a>
          {Translate.getTranslation(languageCode, "TEXT_VisitFacebook")}
        </p>
      </div>
    );
  }
}

